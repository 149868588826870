.BoxCards {
  height: 270px;
  width: 300px;

  overflow: hidden;

  margin: 0.5rem;

  box-shadow: 0.2rem 0.2rem 0.5rem 0.2rem rgba(0, 0, 0, 0.3);
  border-radius: 10px;

  background-color: white;
  cursor: pointer;
}

.BoxCards:hover{
  -webkit-transition: all 200ms ease-in;
  -webkit-transform: scale(1.05);
  -ms-transition: all 200ms ease-in;
  -ms-transform: scale(1.05);   
  -moz-transition: all 200ms ease-in;
  -moz-transform: scale(1.05);
  transition: all 200ms ease-in;
  transform: scale(1.05);
}
