.SignalAndBateryContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  width: 100%;

  margin-left: 0.25rem;
}

.SignalAndBateryContainer.rowDirection {
  @extend .SignalAndBateryContainer;
  flex-direction: row;
}

.SignalAndBateryContainer.columnDirection {
  @extend .SignalAndBateryContainer;
  flex-direction: column;
}

.SignalAndBateryContainerInCards {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  margin-left: 0.25rem;
}

.SignalAndBateryContainerInCards .columnDirection {
  @extend .SignalAndBateryContainerInCards;
  flex-direction: column;
}

.SignalAndBateryContainerInCards .rowDirection {
  @extend .SignalAndBateryContainerInCards;
  flex-direction: row;
}
