.boxHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  height: 59px;

  .logo {
    margin-left: 0.5rem;
    width: 12rem;
    height: 2.2rem;
  }

  .logoMobile {
    @extend .logo;

    width: 3.9rem;
    min-width: 3.9rem;
  }

  .buttonsAndMapView {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

.divider {
  height: 1px;
  background-color: #036666;
}

.localidadeContainer {
  display: flex;
  align-items: center;
  width: 28rem;
  max-width: 100%;
  max-height: 40px;

}
