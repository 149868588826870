.personInformationDivHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;

  .personInformationDiv {
    flex: 1;
    padding-left: 1rem;

    .personName {
      width: 190;
      color: #036666;
      font-size: 1rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: normal;
    }

    .personAge {
      font-weight: 300;
      font-size: 0.8rem;
      line-height: normal;
    }
  }

  .fotoIdosoDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.cardLastSeen{
  font-size: 10px;
  font-style: italic;
  color: 'seagreen';
}