.occurrenceHistoryContainer {
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  width: 100%;

  .title {
    text-align: left;
    font-weight: 700;
    color: #036666;
    font-size: 16px;
  }

  .table {
    background-color: white;
    border-radius: 10px;

    margin-top: 0.5rem;

    .tableRow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      td {
        border-bottom: 1px solid rgba(224, 224, 224, 0.3);
        padding: 0.25rem 0.5rem;
      }

      .overflowTextCell {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .dismissButton {
        background-color: #036666;
        text-align: center;
      }
    }
  }
}
