.drawerContainer {
  [class~='MuiDrawer-paper'] {
    background-color: transparent;
    border: none;
    height: 100%;
    width: 420px;
    top: 60px;
  }
}

.drawerContainerCardDetail {
  [class~='MuiDrawer-paper'] {
    background-color: transparent;
    border: none;
    height: 100%;
    width: 420px;
    top: 0;
  }
}
