.downloadAppContainer {
  width: 40rem;
  max-width: 100%;

  .message {
    text-align: justify;
  }

  .badgeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    margin-top: 1rem;

    .googleBadge {
      width: 200px;
      height: 75px;
    }

    .appleBadge {
      width: 200px;
      height: 60px;
    }
  }
}
