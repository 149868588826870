.boxInformationContainer {
  height: calc(100% - 60px);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 0.5rem;
  background-color: #ececec;

  overflow: auto;

  hr {
    width: 100%;
    margin: 0.6rem 0;
  }

  .noPersonSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: center;
    gap: 1rem;

    width: 100%;
    height: 100%;
  }

  .cancelEmergencyButton {
    background-color: rgb(201, 0, 0);

    margin: 1rem 0;
  }
}

.boxInformationContainerCardDetail {
  @extend .boxInformationContainer;
  height: 100%;
}
