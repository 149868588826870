.BoxCards {
  height: 100%;
  width: 100%;
  z-index: 10;

  overflow: auto;

  .body {
    height: 100%;
    background-color: #eeeeee;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;

    overflow-y: auto;
  }
}
