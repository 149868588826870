.fullHeightFixedBox {
  display: flex;
  flex-direction: column;
  flex: 1;

  overflow: auto;

  .tableContainer {
    height: 100%;
    border-radius: 0;
    z-index: 10;

    white-space: nowrap;

    .personRow {
      cursor: pointer;

      td {
        padding: 0 0.5rem;
      }

      &:hover {
        background-color: #d5d5d5;
      }
    }

    .personRow.active {
      background-color: #d5d5d5;
    }

    .personRow.Critical {
      background-color: rgba(255, 0, 0, 0.05);

      * {
        color: red;
      }
    }

    .personRow.Warning {
      background-color: rgba(255, 130, 0, 0.1);

      * {
        color: orange;
      }
    }

    // .personRow.Information {
    //   background-color: rgba(0, 0, 255, 0.1);

    //   * {
    //     color: blue;
    //   }
    // }
  }
}
