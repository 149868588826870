$color-primary: #036666;

.tableMeasurementContainer {
  width: 100%;

  vertical-align: middle;

  .measurementTable {
    min-width: 350px;

    .tableRow {
      cursor: pointer;

      .measurementTypeContainer {
        height: 100%;
        border: none;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
      }

      .buttonsContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        border: none;

        .buttonIcon {
          color: $color-primary;
          font-size: 20px;
        }
        .buttonIconOff {
          color: 'grey';
          font-size: 20px;
        }
      }

      td {
        padding: 0 0.5rem;
        border: none;
      }
    }

    .tableRowSelected {
      background-color: #036666;
      color: white;

      & {
        td:first-child {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        }
        td:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }

      * {
        color: white;
      }
    }
  }
}

[class~='material-symbols-outlined'] {
  user-select: none;
}

[class~='color-primary'] {
  color: $color-primary;
}
