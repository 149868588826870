.accountInfoContainer {
  position: relative;
  margin: 0.5rem;

  .buttonBackground {
    border-radius: 50%;
    background-color: white;
  }

  .userEmail {
    margin-left: 0.25rem;
  }
}
