$color-primary: #036666;

.tableMeasurementContainer {
  height: 130px;
  width: 100%;

  vertical-align: middle;

  .measurementTable {
    width: 100%;
    margin-left: 0.8rem;

    .tableCell {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.2rem;
    }

    .tableRow {
      .measurementTypeContainer {
        height: 100%;
        border: none;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.4rem;
      }

      td {
        padding: 0.2rem 0.5rem;
        border: none;
      }
    }

    .tableRowSelected {
      background-color: #036666;
      color: white;

      & {
        td:first-child {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        }
        td:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }

      * {
        color: white;
      }
    }
  }
}

[class~='material-symbols-outlined'] {
  user-select: none;
}

[class~='color-primary'] {
  color: $color-primary;
}

.cardLastSeen{
  margin-bottom: 2px;
  font-size: 10px;
  padding-left: 32px;
  font-style: italic;
  color: 'seagreen';
}