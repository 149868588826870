body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  all: unset;
  cursor: pointer;
}

html,
body {
  height: 100%;
}

#root {
  height: inherit;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  scrollbar-width: thin;
  scrollbar-color: grey transparent;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 5px;
}
