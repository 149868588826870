$color-primary: #036666;

.mainContainer {
  display: flex;
  height: 100%;

  .loginContainer {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 2rem;

    @media (max-width: 1020px) {
      width: 100%;
    }

    .formContainer {
      width: 100%;
      padding: 2rem;
      border-radius: 10px;
      box-shadow: 5px 5px 5px -1px rgba(150, 149, 149, 0.52);
      border: 1px solid rgb(241, 241, 241);

      .logoContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5rem;

        .logo {
          width: 96px;
          max-width: 100%;
        }
      }

      .title {
        border: none;
        font-weight: bold;
        font-size: 1.3rem;
        margin: 0;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      .fieldset {
        display: flex;
        flex-direction: column;
        border: none;
      }

      .subtitle {
        color: rgb(155, 153, 153);
        margin-top : 8px;
        margin-bottom : 8px;
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        .rememberMeChecked span {
          color: $color-primary;
        }

        .buttonLogin {
          padding: 0.2rem 1.65rem;
          background-color: $color-primary;
          margin-top: 10px;
          margin-left: auto;
       
        }

        .buttonLogin:disabled {
          background-color: rgba(0, 0, 0, 0.12);
        }
      }

      .ContainerLink {
        text-align: center;
        margin-top: 1rem;
        a {
          color: var(--cor--buttonsLogin);
          text-decoration: none;
          font-weight: bold;
        }

        .Link {
          color: #4eaaff;
          text-decoration: none;
          font-weight: bold;
        }
      }
    }

    .footerLogin {
      bottom: 1rem;
      position: absolute;
      text-align: center;
      font-size: 11px;
    }
  }

  .imageContainer {
    width: 55%;
    height: 100%;

    .imageBackground {
      width: 100%;
      height: 100%;
      display: flex;
      object-fit: cover;
    }

    @media (max-width: 1020px) {
      display: none;
    }
  }
  .footer {
    font-size: 11px;
    font-weight: 400;
  
    text-align: center;
  
    margin-top: auto;
    padding: 0.5rem;
  
    position: absolute;
  
    bottom: 0;
 
  }
}

