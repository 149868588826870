.personInformationDivHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;

  .personInformationDiv {
    flex: 1;

    .personName {
      color: #036666;
      width: 100%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }

    .personAge {
      font-weight: 300;
      font-size: 1rem;
    }

    .personLastSeen {
      font-weight: 300;
      font-size: 0.7rem;
      font-style: italic;
      color: #036666;
    }
  }

  .fotoIdosoDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
