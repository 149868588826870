.filterDateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.25rem;

  padding-top: 20px;
  padding-bottom: 10px;

  width: 20rem;
  max-width: 100%;

  .dateType {
    padding-left: 10px;
  }
}
