.CardFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .boxOccurrences {
    @extend .CardFooter;
  }

  .occurrences {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
