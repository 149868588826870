.drawerContainer {
  [class~='MuiDrawer-paper'] {
    height: 30%;

    left: 420px;
    right: 0;

    overflow-y: unset;
  }
}

.drawerContainerOff {
  display: none;
}

.drawerContainerFixed {
  @extend .drawerContainer;

  [class~='MuiDrawer-paper'] {
    height: 100%;
    top: 60px;
  }
}

.drawerContainerFullHeight {
  @extend .drawerContainer;

  [class~='MuiDrawer-paper'] {
    height: 100%;
    right: 0;
    left: 420px;
    top: 60px;
  }
}
