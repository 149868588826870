.changeViewMapList {
  list-style-type: none;

  margin: 0.5rem;

  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  background-color: white;

  border-radius: 10px;

  transition: all 0.25s ease-in-out;

  .changeViewMapItem {
    display: flex;
    flex-direction: column;

    .selected {
      color: #0bd9d9;
    }
  }
}
