.personInformationDivContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;

  .personInformationDivNameContainer {
    width: 70%;

    .personName {
      width: 90%;
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .personInformationDivPhotoContainer {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .photo {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
  }
}
