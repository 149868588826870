.body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: 60px;
  }

  .mapPage {
    height: 100%;
    flex-direction: column-reverse;
    align-items: center;
  }
}
