.chartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: white;
  border-radius: 10px;
  padding: 0 0.5rem;

  width: 100%;

  font-size: 14px;

  .chart {
    position: relative;
    width: 100%;

    .loadingChartWrapper {
      opacity: 0.5;
    }

    .chartLabelContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 0.5rem;

      color: black;

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
      }

      * {
        color: #036666;
      }
    }

    [id='graphic'] {
      width: 400px !important;
      height: 240px !important;

      max-width: 100%;
      max-height: 100%;

      margin: 0.3rem auto;
    }

    .loadingContainer {
      position: absolute;
      inset: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      .loadingIndicator {
        color: #036666;
      }
    }
  }
}
