.searchContainer {
  display: flex;
  align-items: center;
  width: 28rem;
  max-width: 100%;
  max-height: 40px;

  .searchField {
    padding: 1rem;
  }
}