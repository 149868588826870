.myMapFull {
  height: 100%;
  width: 100%;

  position: relative !important;
}

.myMapFullDetailsMenu {
  left: 420px;
  bottom: 30%;
  top: 60px;;
  right: 0;

  position: fixed !important;
}
